import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Select from "react-select";

import AppContext from "../../../AppContext";

import * as S from "./styles";

const options = [
  { value: "objet mobilier", label: "Objet, mobilier" },
  { value: "design produit", label: "Design produit" },
  { value: "design service", label: "Design service, UX, UI" },
  { value: "design global", label: "Design global" },
  { value: "design textile", label: "Design textile, stylisme" },
  { value: "architecture ", label: "Architecture, espace " },
  { value: "graphisme", label: "Graphisme, illustration" },
  { value: "art", label: "Métiers d’art" },
  { value: "autres", label: "Autres" },
];

const Form = ({ className = "", id }) => {
  const { referrerId = "" } = useParams();
  const [email, setEmail] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [status, setStatus] = useState(null);

  const config = useContext(AppContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || email.indexOf("@") < 0) return;
    setStatus("sending");

    const data = {
      email,
      domain: selectedOption?.value,
      referrerId,
    };

    const response = await fetch(`${config.API_URL}/newsletter`, {
      method: "POST",
      mode: "cors",
      redirect: "follow",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      setStatus("error");
      throw new Error(`Response status: ${response.status}`);
    }
    setStatus("success");
  };

  return (
    <S.Form className={className} id={id}>
      {status === "sending" && <div>Inscription en cours...</div>}
      {status === "success" && <div>C'est enregistré. Rendez-vous mardi !</div>}

      {!status && (
        <>
          <S.Title>Pour la recevoir, abonnez-vous</S.Title>

          <S.Label htmlFor="email">Votre e-mail</S.Label>
          <S.Input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Renseignez votre adresse"
          />

          <S.Label htmlFor="category">
            Votre terrain de jeu
            <span>
              Cette info optionnelle nous permet de mieux vous connaître et
              d'adapter nos contenus
            </span>
          </S.Label>
          <Select
            defaultValue={selectedOption}
            isSearchable={false}
            menuPortalTarget={document.body}
            onChange={setSelectedOption}
            options={options}
            placeholder="Sélectionnez un domaine"
            styles={{
              container: (base) => ({
                ...base,
                borderColor: "#010023",
              }),
              control: (base) => ({
                ...base,
                borderColor: "#010023",
                font: "300 16px Poppins, sans-serif",
                boxShadow: "none",
                "&:hover": { borderColor: "#010023" },
              }),
              menu: (base) => ({
                ...base,
                borderColor: "#010023",
                font: "300 16px Poppins, sans-serif",
              }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />

          <S.Submit type="submit" onClick={handleSubmit}>
            S'abonner
          </S.Submit>
        </>
      )}
    </S.Form>
  );
};

Form.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
};

export default Form;
